<template>
  <div class="login">
    <h2 class="typography-h2"><n-html :text="$t('error.404.title')" /></h2>
    <div>
      <p class="typography-p-regular">
        <n-html :text="$t('error.404.subtitle')" />
      </p>
      <n-button-regular
        full-width
        @click.native="$router.push({ name: 'overview' })"
      >
        <n-html :text="$t('error.404.button')" />
      </n-button-regular>
    </div>
  </div>
</template>

<script>
export default {
  name: "pages.error.404"
};
</script>
